<template>
  <div>
    <header-main></header-main>
    <section
      v-if="!this.data_empty"
      class="section section-employee section-sm section-first bg-default"
    >
      <v-container>
        <v-row class="justify-content-center card-carne">
          <v-col class="col-12 col-xl-4 card-carne__inner">
            <div class="card-planes middle">
              <div class="card-planes__inner" id="plan1">
                <div class="card-planes__front" id="plan1front">
                  <img
                    class="carne"
                    id="carne"
                    :src="`https://appears.com.co/images/carne/${this.$route.params.id}_front.png`"
                    alt="Carné empleado parte frontal"
                  />
                </div>
                <div class="card-planes__back" id="plan1back">
                  <img
                    class="carne"
                    id="carne"
                    :src="`https://appears.com.co/images/carne/${this.$route.params.id}_back.png`"
                    alt="Carné empleado parte posterior"
                  />
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="col-12 col-xl-8 data-employee">
            <div class="row">
              <div class="col-12">
                <span class="data-employee__title">Datos de empleado</span>
              </div>
            </div>
            <div class="row row-mod">
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Nombre completo:</strong> </span>
                <p class="data-employee__p">{{ nombre }}</p>
              </div>
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Id empleado:</strong> </span>
                <p class="data-employee__p">{{ cedula }}</p>
              </div>
            </div>
            <div class="row row-mod">
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Rh:</strong> </span>
                <p class="data-employee__p">{{ rh }}</p>
              </div>
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Departamento:</strong> </span>
                <p class="data-employee__p">{{ departamento }}</p>
              </div>
            </div>
            <div class="row row-mod">
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Teléfono:</strong> </span>
                <p class="data-employee__p">{{ telefono }}</p>
              </div>
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Correo:</strong> </span>
                <p class="data-employee__p">{{ correo }}</p>
              </div>
            </div>
            <div class="row row-mod">
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Cargo:</strong> </span>
                <p class="data-employee__p">{{ cargo }}</p>
              </div>
              <div class="col-12 col-md-6 data-item">
                <span> <strong>Estado de contrato:</strong> </span> <br />
                <span
                  v-bind:class="{ badgeEstado: estado_contrato == 1 }"
                  class="badge badgeEstadoRed"
                  >{{ estado }}</span
                >
              </div>
            </div>
            <div class="row row-mod">
              <div class="col-12 data-item">
                <span> <strong>Descripcion del cargo:</strong> </span>
                <p class="data-employee__p">
                  {{ descripcion_cargo }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section
      v-if="this.data_empty"
      class="section section-employee section-sm section-first bg-default"
    >
      <div class="container">
        <h3><strong>Empleado</strong></h3>
        <div class="row row-30 justify-content-center card-carne">
          <div class="col-12 col-xl-4 card-carne__inner">
            <div class="card-planes middle">
              <div class="card-planes__inner" id="plan1">
                <div class="card-planes__front" id="plan1front">
                  <img
                    class="carne"
                    id="carne"
                    src="images/carne/<?php echo $cedula ?>_front.png"
                    alt="Carné empleado parte frontal"
                  />
                </div>
                <div class="card-planes__back" id="plan1back">
                  <img
                    class="carne"
                    id="carne"
                    src="images/carne/<?php echo $cedula ?>_back.png"
                    alt="Carné empleado parte posterior"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-8 data-employee">
            <div class="row">
              <div class="col-12">
                <span class="data-employee__title"
                  >Empleado existe en nuestra base de datos</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";
import FooterMain from "@/components/Footer.vue";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Collaborator",
  components: {
    HeaderMain,
    FooterMain,
  },
  data() {
    return {
      id_collaborator: this.$route.params.id,
      data_empty: false,
      nombre: "",
      cedula: "",
      rh: "",
      departamento: "",
      telefono: "",
      correo: "",
      cargo: "",
      estado_contrato: "",
      estado: "",
      descripcion_cargo: "",
    };
  },
  created() {
    this.ShowDataEmployee();
  },
  methods: {
    ...mapActions("main", ["GetDataCollaborator"]),
    ShowDataEmployee() {
      try {
        axios({
          method: "get",
          url:
            "https://apis.qinspecting.com/newapp/Select_valitacion_PA/" +
            this.id_collaborator,
        })
          .then((resp) => resp.data)
          .then((data) => {
            if (data.length === 0) {
              this.data_empty = true;
            } else if (data.length > 0) {
              this.nombre =
                data[0].Primer_Nombre +
                " " +
                data[0].Segundo_Nombre +
                " " +
                data[0].Primer_Apellido +
                " " +
                data[0].Segundo_Apellido;
              this.cedula = data[0].Cedula;
              this.rh = data[0].Rh;
              this.departamento = data[0].Departamento_area;
              this.telefono = data[0].Celular;
              this.correo = data[0].Email_Corporativo;
              this.cargo = data[0].Cargo;
              this.estado_contrato = data[0].Estado_contrato;
              if (this.estado_contrato == 1) {
                this.estado = "Activo";
              } else if (this.estado_contrato == 0) {
                this.estado = "Inactivo";
              }
              this.descripcion_cargo = data[0].Descripcion_cargo;
            }
          })
          .catch((error) => {
            console.log("Error en la peticion");
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Employee */
.content-carne {
  display: flex;
}

.carne {
  height: 15.6cm;
}

.section-employee {
  margin: auto;
  max-width: 1200px;
  padding-top: 50px !important;
  margin-bottom: 50px;
}

/* Carne */
.card-planes {
  padding: 0;
  /* display: grid; */
  margin: auto;
  cursor: pointer;
  width: 9.8cm;
  height: 15.6cm;
  perspective: 1000px;
}

.card-planes__inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  -webkit-transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
}

/* .card-planes:hover .card-planes__inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
} */

.poner_hover {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.quitar_hover {
  transform: none !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
}

.card-planes__front,
.card-planes__back {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s linear;
  -webkit-transition: transform 0.6s linear;
  -moz-transition: transform 0.6s linear;
}

.card-planes__front {
  background: var(--white-color-s);
  border-radius: 15px;
  transform: perspective(600px) rotateY(0deg);
  -webkit-transform: perspective(600px) rotateY(0deg);
  -moz-transform: perspective(600px) rotateY(0deg);
  -webkit-box-shadow: 4px 2px 22px -6px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 4px 2px 22px -6px rgba(0, 0, 0, 0.46);
  box-shadow: 4px 2px 22px -6px rgba(0, 0, 0, 0.46);
  text-align: center;
}

.card-planes__front-title {
  font-size: 22px;
}

.card-planes__front-description {
  font-size: 17px;
  margin-top: 20px;
  padding: 0 10px;
  margin-bottom: 0;
}

.card-planes__front-btn {
  height: 35px;
  margin-top: 30px;
  color: var(--white-color);
  font-size: 17px;
  font-weight: 600;
  background: var(--tertiary-color);
  border: none;
  border-radius: 15px;
  padding: 0 20px;
}

.card-planes__back {
  border-radius: 15px;
  background: var(--white-color-s);
  -webkit-box-shadow: 4px 2px 22px -6px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 4px 2px 22px -6px rgba(0, 0, 0, 0.46);
  box-shadow: 4px 2px 22px -6px rgba(0, 0, 0, 0.46);
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.card-planes__back-content {
  text-align: center;
  width: 100%;
}

.card-planes__back-title {
  margin-bottom: 20px;
  display: block;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
}

.card-planes__back-item {
  display: grid;
  grid-template-columns: 8% 92%;
  margin: 10px 20px;
  text-align: left;
}

@media screen and (max-width: 360px) {
  .card-planes {
    width: 300px;
  }
  .card-planes__front-img {
    height: 50%;
    margin-bottom: 45px;
  }
  .swiper-slide {
    width: 300px;
  }
  .card-planes__back-item {
    font-size: 15px;
  }
}

.row-mod {
  margin-top: 0px !important;
}

.data-item {
  margin-top: 20px !important;
}

.data-employee {
  padding: 30px;
  border-radius: 15px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.73);
  -moz-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.73);
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.73);
  text-align: left;
}

.data-employee__title {
  font-size: 22px;
  font-weight: bold;
}

.data-employee__p {
  margin-top: 0;
}

.badgeEstado {
  text-transform: none;
  background: #17a05d !important;
  border-radius: 15px;
  padding: 1px 10px;
}

.badgeEstadoRed {
  text-transform: none;
  background: #d70f21;
  border-radius: 15px;
  padding: 1px 10px;
}
</style>
